import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material.module';

// import { MsAdalAngular6Module, AuthenticationGuard} from 'microsoft-adal-angular6';

import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// import { GridModule } from '@syncfusion/ej2-angular-grids';

export function getAdalConfig() {
  return {
      tenant: '38915c76-dc6c-4581-a636-06243ba7961b',
      clientId: '3e483fdd-d1fd-4b6d-811e-ab6b06202333',
      redirectUri: window.location.origin,
      endpoints: {
        //'http://localhost:8100' : 'https://login.microsoftonline.com/',
         "https://graph.microsoft.com/v1.0/me/":"https://graph.microsoft.com/"
      },
      navigateToLoginRequestUrl: false,
      cacheLocation: 'localStorage',
    };
}

// export function loggerCallback(logLevel, message, piiEnabled) {
//   console.log("client logging" + message);
// }
export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']]
];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    // MsAdalAngular6Module.forRoot(getAdalConfig),
    // GridModule
    MsalModule.forRoot({
      auth: {
        clientId: '3e483fdd-d1fd-4b6d-811e-ab6b06202333',
        // clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6',
        authority: 'https://login.microsoftonline.com/38915c76-dc6c-4581-a636-06243ba7961b',
        // authority: "https://login.microsoftonline.com/common/oauth2/nativeclient",
        validateAuthority: true,
        //redirectUri: 'http://localhost:8100/',
        redirectUri: 'https://smart-meterweb.now.sh',
        //postLogoutRedirectUri: 'http://localhost:8100/',
        postLogoutRedirectUri: 'https://smart-meterweb.now.sh',
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
    {
      //popUp: !isIE,
      consentScopes: [
        'user.read',
        'openid',
        'profile',
        //"api://a88bb933-319c-41b5-9f04-eff36d985612/access_as_user"
        // "api://ee84299c-7fea-4faa-ab54-849be62bb9f4/access_as_user"
      ],
      unprotectedResources: ['https://www.microsoft.com/en-us/'],
      protectedResourceMap,
      extraQueryParameters: {}
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    //AuthenticationGuard
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
