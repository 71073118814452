import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
//import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { AppComponent } from './app.component';

import { MsalGuard } from '@azure/msal-angular';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
    // path: 'home', component: AppComponent, pathMatch: 'full', canActivate: [MsalGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate : [MsalGuard]
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'meter-water',
    loadChildren: () => import('./process/meter-water/meter-water.module').then( m => m.MeterWaterPageModule)
  },
  {
    path: 'meter-ele',
    loadChildren: () => import('./process/meter-ele/meter-ele.module').then( m => m.MeterElePageModule)
  },
  {
    path: 'project-list',
    loadChildren: () => import('./projects/project-list/project-list.module').then( m => m.ProjectListPageModule)
  },
  {
    path: 'unit-list',
    loadChildren: () => import('./projects/unit-list/unit-list.module').then( m => m.UnitListPageModule),
  },
  {
    path: 'unit-list-ex',
    loadChildren: () => import('./projects/unit-list-ex/unit-list-ex.module').then( m => m.UnitListExPageModule)
  },
  {
    path: 'unit-detail',
    loadChildren: () => import('./projects/unit-detail/unit-detail.module').then( m => m.UnitDetailPageModule)
  },
  {
    path: 'water-list',
    loadChildren: () => import('./setting/meter/water-list/water-list.module').then( m => m.WaterListPageModule)
  },
  {
    path: 'ele-meter',
    loadChildren: () => import('./setting/meter/ele-meter/ele-meter.module').then( m => m.EleMeterPageModule)
  },
  {
    path: 'water-detail',
    loadChildren: () => import('./setting/meter/water-detail/water-detail.module').then( m => m.WaterDetailPageModule)
  },
  {
    path: 'meter-detail',
    loadChildren: () => import('./setting/meter/meter-detail/meter-detail.module').then( m => m.MeterDetailPageModule)
  },
  {
    path: 'tr',
    loadChildren: () => import('./setting/tr/tr.module').then( m => m.TrPageModule)
  },
  {
    path: 'code',
    loadChildren: () => import('./setting/code/code.module').then( m => m.CodePageModule)
  },
  {
    path: 'tr-detail',
    loadChildren: () => import('./setting/tr-detail/tr-detail.module').then( m => m.TrDetailPageModule)
  },
  {
    path: 'code-detail',
    loadChildren: () => import('./setting/code-detail/code-detail.module').then( m => m.CodeDetailPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
