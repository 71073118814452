import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, SecurityContext } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

const GRAPH_PHOTO_ENDPOINT = 'https://graph.microsoft.com/v1.0/me/photo/$value';
const GRAPH_ME_ENDPOINT = 'https://graph.microsoft.com/v1.0/me/';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  private photo: SafeUrl;
  public  bUrl: any;
  public profile_data: any;

  public data: object[];
  isIframe = false;
  loggedIn = false;
  public appPages = [
    {
      title: 'Dashboard',
      // url: '/home',
      icon: 'home',
      child: [
        {title: 'Main', url: '/home', icon: 'home'},
      ]
    },
    {
      title: 'Record',
      // url: '/list',
      icon: 'list',
      child: [
        {title: 'Water', url: 'meter-water', icon: 'water'},
        {title: 'Electric', url: 'meter-ele', icon: 'flash'},
      ]
    },
    {
      title: 'Projects',
      // url: '/list',
      icon: 'apps',
      child: [
        {title: 'Project list', url: 'project-list', icon: 'browsers'},
        {title: 'Unit List', url: 'unit-list-ex', icon: 'apps'},
      ]
    },
    {
      title: 'Master',
      // url: '/list',
      icon: 'settings',
      child: [
        {title: 'Water meter', url: 'water-list', icon: 'hammer'},
        {title: 'Electric meter', url: 'ele-meter', icon: 'hammer'},
        {title: 'TR', url: 'tr', icon: 'hammer'},
        {title: 'Code', url: 'code', icon: 'hammer'},
      ]
    },
    {
      title: 'Setting',
      // url: '/list',
      icon: 'settings',
      child: [
        {title: 'Authorize', url: 'setting_users', icon: 'hammer'},
        {title: 'Config', url: 'setting_users', icon: 'hammer'},
      ]
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private http: HttpClient,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private dom: DomSanitizer
    // private adalSvc: MsAdalAngular6Service
  ) {
    // console.log("Log app component");
    // console.log(this.adalSvc.userInfo);

    // console.log(this.adalSvc.)
    // console.log(this.adalSvc.accessToken);
    // console.log(this.adalSvc.RenewToken('https://graph.microsoft.com/v1.0/me/'));


    // this.adalSvc.acquireToken('https://graph.microsoft.com').subscribe((token: string) => {
      // console.log(token);
      // const httpOptions = {
      //   headers: new HttpHeaders({
      //     // 'Content-Type':  'image/jpeg',
      //   })
      // };
      // this.http.get('https://graph.microsoft.com/v1.0/me/',httpOptions).subscribe((data: any) => {
      //     console.log(data)
      //   });
      //     console.log(data)
      //   });
    // });


    this.initializeApp();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    this.checkoutAccount();

    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkoutAccount();
    });

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

      console.log('Redirect Success: ', response.accessToken);
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));
  }

  checkoutAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    // if (isIE) {
      this.authService.loginRedirect();
    // } else {
    //  this.authService.loginPopup();
    // }
  }

  logout() {
    this.authService.logout();
  }



  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      
    });

    // this.data = data;
    this.getPhoto();
    // console.log(this.getProfile());
  }

  getPhoto() {
    this.http.get(GRAPH_PHOTO_ENDPOINT,{ responseType: 'blob'})
      .toPromise().then(profile => {
        // this.photo = "data:image/png;base64,"+btoa(profile.toString());
        const reader = new FileReader();
        reader.readAsDataURL(profile);
        const self = this;
        // tslint:disable-next-line: only-arrow-functions
        reader.onloadend = function() {
          self.bUrl = reader.result;
          // console.log(self.bUrl);
        }
        this.getProfile();
      });
  }

  getProfile() {
    this.http.get(GRAPH_ME_ENDPOINT)
      .toPromise().then(profile => {
        this.profile_data = profile;
        if (this.profile_data) {
          localStorage.setItem('user_profile', this.profile_data);
        }
        console.log(this.profile_data);
      });
  }
}
