import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatTableModule,
  MatBadgeModule,
  MatStepperModule,
  MatGridListModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatIconModule,
  MatPaginatorModule,
  MatSortModule,
  MatRadioModule,
  MatToolbarModule,
  MatSidenavModule,
  MatExpansionModule,
  MatTreeModule,
  MatCardModule
} from '@angular/material';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    MatTableModule,
    MatBadgeModule,
    MatStepperModule,
    MatGridListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    MatToolbarModule,
    MatSidenavModule,
    MatExpansionModule,
    MatTreeModule,
    MatCardModule
  ]
})
export class MaterialModule { }
